import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { CapitalDeposit, CapitalDepositEligibilityCheckResponse, CapitalDepositGenericResponse } from '../dto';
import { CapitalDepositHttpService } from '../http';

interface CapitalDepositState {
  capitalDeposit: CapitalDeposit;
}

@Injectable({
  providedIn: 'root',
})
export class CapitalDepositStateService extends StateService<CapitalDepositState> {
  readonly capitalDeposit$: Observable<CapitalDeposit> = this.select((state) => state.capitalDeposit);

  constructor(
    private readonly capitalDepositHttpService: CapitalDepositHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshCapitalDeposit(company: Company): Promise<void> {
    try {
      const capitalDeposit = await lastValueFrom(this.capitalDepositHttpService.getState(company.id));
      this.setState({ capitalDeposit });
    } catch (e: any) {
      this.logger.error(e);
    }
  }

  async checkEligibility(company: Company): Promise<CapitalDepositEligibilityCheckResponse | null> {
    try {
      return await lastValueFrom(this.capitalDepositHttpService.checkEligibility(company.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async startCapitalDepositForCompany(company: Company): Promise<CapitalDepositGenericResponse | null> {
    try {
      return await lastValueFrom(this.capitalDepositHttpService.startCapitalDepositForCompany(company.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async sendAssociatesProofOfIdentity(company: Company): Promise<CapitalDepositGenericResponse | null> {
    try {
      return await lastValueFrom(this.capitalDepositHttpService.sendAssociatesProofOfIdentity(company.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async sendCompanyProofOfAddress(company: Company): Promise<CapitalDepositGenericResponse | null> {
    try {
      return await lastValueFrom(this.capitalDepositHttpService.sendCompanyProofOfAddress(company.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async sendCompanyBylawsDocument(company: Company): Promise<CapitalDepositGenericResponse | null> {
    try {
      return await lastValueFrom(this.capitalDepositHttpService.sendCompanyBylawsDocument(company.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async sendCompanyKbis(company: Company): Promise<CapitalDepositGenericResponse | null> {
    try {
      return await lastValueFrom(this.capitalDepositHttpService.sendCompanyKbis(company.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }
}
