import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WizardBreakpointService {
  constructor(public readonly breakpointObserver: BreakpointObserver) {}

  getIsMatched(sizeNames: string[]): Observable<boolean> {
    return this.breakpointObserver.observe([...sizeNames]).pipe(
      map(({ matches }) => {
        return matches;
      }),
    );
  }
}
