import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Profile } from '@dougs/user/dto';
import { CalendlyResponse, HubspotResponse } from '../dto';
import { CrmHttpService } from '../http';

@Injectable({
  providedIn: 'root',
})
export class CrmService {
  constructor(private readonly crmHttpService: CrmHttpService) {}

  async getGenericOrOwnerMeetingLink(userId: number): Promise<HubspotResponse> {
    try {
      const res = await lastValueFrom(this.crmHttpService.meetingLink(userId));
      return res.status === 200
        ? {
            isAvailable: true,
            url: res.body.url,
          }
        : {
            isAvailable: false,
            url: 'https://meetings.hubspot.com/thomas-fournier/rendez-vous-tarification-dougs',
          };
    } catch (e) {
      return {
        isAvailable: false,
        url: 'https://meetings.hubspot.com/thomas-fournier/rendez-vous-tarification-dougs',
      };
    }
  }

  getCalendlySalesLink(userProfile: Profile): Promise<CalendlyResponse> {
    return lastValueFrom(this.crmHttpService.salesMeetingCalendlyLink(userProfile));
  }

  getCalendlyAccountDeveloperLink(userId: number): Promise<CalendlyResponse> {
    return lastValueFrom(this.crmHttpService.accountDeveloperMeetingCalendlyLink(userId));
  }
}
