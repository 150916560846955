import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { Onboarding } from '../dto/onboarding.dto';

@Injectable({
  providedIn: 'root',
})
export class OnboardingHttp {
  constructor(private readonly http: HttpClient) {}

  getStages(company: Company): Observable<Onboarding> {
    return this.http.get<Onboarding>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/legal-onboarding`,
    );
  }
}
