import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Profile } from '@dougs/user/dto';
import { CalendlyResponse } from '../dto';

@Injectable({
  providedIn: 'root',
})
export class CrmHttpService {
  constructor(private readonly http: HttpClient) {}

  meetingLink(userId: number): Observable<HttpResponse<any>> {
    return this.http.get(`${AppConfig.settings.crm.baseUrl}/meetings/${userId}`, {
      headers: {
        // Ne pas afficher de message si la requête retourne un 404
        ['X-Silent-User-Message']: 'true',
      },
      observe: 'response',
    });
  }

  salesMeetingCalendlyLink(userProfile: Profile): Observable<CalendlyResponse> {
    return this.http.post<CalendlyResponse>(`${AppConfig.settings.crm.baseUrl}/calendly-link`, {
      observe: 'response',
      email: userProfile.email,
      phone: userProfile.phone,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      force: true,
    });
  }

  accountDeveloperMeetingCalendlyLink(userId: number): Observable<CalendlyResponse> {
    return this.http.get<CalendlyResponse>(
      `${AppConfig.settings.crm.baseUrl}/calendly-link/account-developer/${userId}`,
    );
  }
}
