import { Injectable } from '@angular/core';
import { LoggerService } from '@dougs/core/logger';
import { Profile } from '@dougs/user/dto';
import { CalendlyResponse, HubspotResponse } from '../dto';
import { CrmService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class CrmStateService {
  constructor(
    private readonly logger: LoggerService,
    private readonly crmService: CrmService,
  ) {}

  async getGenericOrOwnerMeetingLink(userId: number): Promise<HubspotResponse | undefined> {
    try {
      return await this.crmService.getGenericOrOwnerMeetingLink(userId);
    } catch (e) {
      this.logger.error(e);
    }
  }

  getCalendlySalesLink(userProfile: Profile): Promise<CalendlyResponse> {
    return this.crmService.getCalendlySalesLink(userProfile);
  }

  getCalendlyAccountDeveloperLink(userId: number): Promise<CalendlyResponse> {
    return this.crmService.getCalendlyAccountDeveloperLink(userId);
  }
}
