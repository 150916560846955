import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { CapitalDeposit, CapitalDepositEligibilityCheckResponse, CapitalDepositGenericResponse } from '../dto';

@Injectable({
  providedIn: 'root',
})
export class CapitalDepositHttpService {
  constructor(private readonly http: HttpClient) {}

  getState(companyId: number): Observable<CapitalDeposit> {
    return this.http.get<CapitalDeposit>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/capital-deposit/state`,
    );
  }

  checkEligibility(companyId: number): Observable<CapitalDepositEligibilityCheckResponse> {
    return this.http.get<CapitalDepositEligibilityCheckResponse>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/capital-deposit/check-eligibility`,
    );
  }

  startCapitalDepositForCompany(companyId: number): Observable<CapitalDepositGenericResponse> {
    return this.http.post<CapitalDepositGenericResponse>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/capital-deposit/start`,
      {},
    );
  }

  sendAssociatesProofOfIdentity(companyId: number): Observable<CapitalDepositGenericResponse> {
    return this.http.get<CapitalDepositGenericResponse>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/capital-deposit/actions/send-associates-proof-of-identity`,
    );
  }

  sendCompanyProofOfAddress(companyId: number): Observable<CapitalDepositGenericResponse> {
    return this.http.get<CapitalDepositGenericResponse>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/capital-deposit/actions/send-company-proof-of-address`,
    );
  }

  sendCompanyBylawsDocument(companyId: number): Observable<CapitalDepositGenericResponse> {
    return this.http.get<CapitalDepositGenericResponse>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/capital-deposit/actions/send-company-bylaws-document`,
    );
  }

  sendCompanyKbis(companyId: number): Observable<CapitalDepositGenericResponse> {
    return this.http.get<CapitalDepositGenericResponse>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/capital-deposit/actions/send-company-kbis`,
    );
  }
}
